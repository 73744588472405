import { useQuery } from '@apollo/client'
import { Flex, Skeleton } from '@chaine/keychaine'
import { uniqueId } from 'lodash'
import React, { useContext, useState } from 'react'
import { useParams } from 'react-router-dom'

import { COMPANY_INTELLIGENCE } from '../../../booking/data'
import { TAssociationField, TFMCSAChangesField } from '../../../booking/types'
import { MOTOR_CARRIER_MODIFICATIONS } from '../../data'
import { DataContext } from '../../index'
import { mergeAddress, mergeModificationsWithMetadata } from '../../utils/utils'
import { Association, AssociationView } from '../association/association'
import CompanyPageLayout, { CompanySectionLayout } from '../layout'
import { AssessmentCard, AuthorityCard, DetailsCard, IntelligenceCard } from '../overview'

/**
 * This tab includes basic information about company
 * for example - authority details, assessment details and its personal details like telephone, email, address
 */
export function OverviewTabPanel() {
  const { companyData, isCompanyDataLoading } = useContext(DataContext)
  const { dotNumber } = useParams()
  const [isOpen, setOpen] = useState(false)
  const [associationView, setAssociationView] = useState({ associationField: '', associationValue: '', title: '' })
  const { data, loading } = useQuery(COMPANY_INTELLIGENCE, {
    variables: {
      dotNumber: dotNumber
    }
  })

  const { data: carrierModifications } = useQuery(MOTOR_CARRIER_MODIFICATIONS, {
    variables: {
      dotNumber: dotNumber,
      type: 'DOT'
    }
  })

  function handleAssociationView(associationField: string, associationValue: string, title: string) {
    setOpen(true)
    setAssociationView({ associationField, associationValue, title })
  }

  const allChanges = mergeModificationsWithMetadata(companyData, carrierModifications?.motorCarrierModifications)
  const physicalAddress = mergeAddress(
    allChanges.physicalStreet,
    allChanges.physicalCity,
    allChanges.physicalState,
    allChanges.physicalZip
  )

  const mailingAddress = mergeAddress(
    allChanges.mailingStreet,
    allChanges.mailingCity,
    allChanges.mailingState,
    allChanges.mailingZip
  )

  return (
    <React.Fragment>
      <CompanyPageLayout
        leftChildren={
          <CompanySectionLayout gap={8}>
            <IntelligenceCard
              contents={[
                {
                  heading: 'FMCSA Census Changes (past 6 months)',
                  items:
                    isCompanyDataLoading || loading
                      ? [
                          <Skeleton height={6} width="60%" borderRadius="base" key={uniqueId()} />,
                          <Skeleton
                            height={6}
                            width="60%"
                            marginTop={2}
                            marginBottom={2}
                            borderRadius="base"
                            key={uniqueId()}
                          />,
                          <Skeleton height={6} width="60%" marginBottom={0} borderRadius="base" key={uniqueId()} />
                        ]
                      : data?.companyIntelligence?.fmcsaChanges?.length > 0
                      ? data?.companyIntelligence?.fmcsaChanges?.map((item: TFMCSAChangesField) => item.alert)
                      : [],
                  warningText: 'This carrier has no changes to their FMCSA Census data'
                },
                {
                  heading: 'Associations',
                  items:
                    isCompanyDataLoading || loading
                      ? [
                          <Skeleton height={6} width="60%" borderRadius="base" key={uniqueId()} />,
                          <Skeleton
                            height={6}
                            width="60%"
                            marginTop={2}
                            marginBottom={2}
                            borderRadius="base"
                            key={uniqueId()}
                          />,
                          <Skeleton height={6} width="60%" marginBottom={0} borderRadius="base" key={uniqueId()} />
                        ]
                      : data?.companyIntelligence?.associations?.length > 0
                      ? data?.companyIntelligence?.associations?.map((item: TAssociationField) => item.alert)
                      : [],
                  warningText: 'This carrier has no associations to their FMCSA Census data'
                }
              ]}
            />
            <DetailsCard
              contents={[
                {
                  content:
                    isCompanyDataLoading || loading ? (
                      <Skeleton height={6} marginTop={1} marginBottom={1} width="70%" />
                    ) : (
                      `+1 ${companyData?.telephone?.oldestValue?.value}`
                    ),
                  heading: 'Telephone'
                },
                {
                  content:
                    isCompanyDataLoading || loading ? (
                      <Skeleton height={6} marginTop={1} marginBottom={1} width="70%" />
                    ) : (
                      companyData?.emailAddress?.oldestValue?.value?.toLocaleLowerCase()
                    ),
                  heading: 'Email Address'
                },
                {
                  content:
                    isCompanyDataLoading || loading ? (
                      <Flex direction="column" gap={1}>
                        {new Array(3).fill(0).map(() => (
                          <Skeleton height={6} width="70%" borderRadius="base" key={uniqueId()} />
                        ))}
                      </Flex>
                    ) : (
                      <>
                        {companyData?.mailingStreet?.oldestValue?.value} <br />{' '}
                        {companyData?.mailingCity?.oldestValue?.value} <br />{' '}
                        {companyData?.mailingState?.oldestValue?.value},{' '}
                        {companyData?.mailingCountry?.oldestValue?.value} {companyData?.mailingZip?.oldestValue?.value}{' '}
                        <br />
                        United States
                      </>
                    ),
                  heading: 'Mailing Address'
                },
                {
                  content:
                    isCompanyDataLoading || loading ? (
                      <Flex direction="column" gap={1}>
                        {new Array(3).fill(0).map(() => (
                          <Skeleton height={6} width="70%" borderRadius="base" key={uniqueId()} />
                        ))}
                      </Flex>
                    ) : (
                      <>
                        {companyData?.physicalStreet?.oldestValue?.value} <br />{' '}
                        {companyData?.physicalCity?.oldestValue?.value} <br />{' '}
                        {companyData?.physicalState?.oldestValue?.value},{' '}
                        {companyData?.physicalCountry?.oldestValue?.value}{' '}
                        {companyData?.physicalZip?.oldestValue?.value} <br />
                        United States
                      </>
                    ),
                  heading: 'Physical Address'
                }
              ]}
            />
          </CompanySectionLayout>
        }
        rightChildren={
          isCompanyDataLoading || loading ? (
            <Flex direction="column" gap={4} marginTop={2}>
              {new Array(3).fill(0).map(() => (
                <Skeleton height={20} marginTop={1} marginBottom={1} borderRadius="base" key={uniqueId()} />
              ))}
            </Flex>
          ) : (
            <CompanySectionLayout gap={8}>
              {companyData?.carrierAuthority?.oldestValue?.value && (
                // Below code commented as we will use it in future versions
                <AssessmentCard
                  contents={[
                    // {
                    //   isDone: true,
                    //   label: 'Inactive Operating Status'
                    // },
                    {
                      isDone: companyData?.carrierAuthority?.oldestValue?.value?.commonAuthorityStatus == 'Active',
                      label: 'Active Authority'
                    }
                    // {
                    //   isDone: true,
                    //   label: 'Active insurance'
                    // },
                    // {
                    //   isDone: true,
                    //   label: 'Inspected past 24 months'
                    // },
                    // {
                    //   isDone: true,
                    //   label: 'Acceptable safety rating'
                    // },
                    // {
                    //   isDone: true,
                    //   label: '8 y/o authority'
                    // }
                  ]}
                />
              )}
              {companyData?.carrierAuthority?.oldestValue?.value && (
                <AuthorityCard
                  contents={[
                    {
                      label: 'Authority',
                      value:
                        companyData?.carrierAuthority?.oldestValue?.value?.authorizedForBroker == 'true'
                          ? 'Broker'
                          : 'Carrier'
                    },
                    {
                      label: 'Broker Authority Status',
                      value: companyData?.carrierAuthority?.oldestValue?.value?.brokerAuthorityStatus
                    },
                    {
                      label: 'Common Authority Status',
                      value: companyData?.carrierAuthority?.oldestValue?.value?.commonAuthorityStatus
                    },
                    {
                      label: 'Contract Authority Status',
                      value: companyData?.carrierAuthority?.oldestValue?.value?.contractAuthorityStatus
                    }
                  ]}
                />
              )}
              {/* <AdditionalInformationCard
              heading={'Inspection'}
              contents={[
                {
                  key: 'inspection-1',
                  label: 'Within the past year',
                  value: '5'
                },
                {
                  key: 'inspection-2',
                  label: 'Total',
                  value: '250'
                }
              ]}
            /> */}
              {/* <AdditionalInformationCard
              heading={'Violations'}
              contents={[
                {
                  key: 'violations-1',
                  label: 'Within the past year',
                  value: '5'
                },
                {
                  key: 'violations-2',
                  label: 'Total',
                  value: '250'
                }
              ]}
            /> */}
              {/* <AdditionalInformationCard
              heading={'Crashes'}
              contents={[
                {
                  key: 'crashes-1',
                  label: 'Within the past year',
                  value: '5'
                },
                {
                  key: 'crashes-2',
                  label: 'Total',
                  value: '250'
                }
              ]}
            /> */}
              <Association
                type={'Email'}
                associationField="emailAddress"
                handleAssociationView={handleAssociationView}
                values={allChanges.emailAddress?.map((ele) => ({ ...ele, value: ele.value?.toLowerCase() })) || []}
              />
              <Association
                type={'Telephone'}
                associationField="telephone"
                handleAssociationView={handleAssociationView}
                values={allChanges.telephone?.map((ele) => ({ ...ele, value: ele.value || 'NULL' })) || []}
              />
              <Association
                type={'Fax'}
                associationField="faxNumber"
                values={
                  allChanges.faxNumber?.map((ele) => ({
                    ...ele,
                    sanitizedValue: ele.value && ele.value.replace(/[^0-9]/g, ''),
                    value: ele.value || 'NULL'
                  })) || []
                }
                handleAssociationView={handleAssociationView}
              />
              <Association
                type={'Physical Address'}
                values={physicalAddress || []}
                associationField="normalizedPhysicalAddress"
                handleAssociationView={handleAssociationView}
              />
              <Association
                type={'Mailing Address'}
                values={mailingAddress || []}
                associationField="normalizedMailingAddress"
                handleAssociationView={handleAssociationView}
              />
              <AssociationView
                isOpen={isOpen}
                title={associationView.title}
                setOpen={setOpen}
                dotNumber={dotNumber || ''}
                associationField={associationView.associationField}
                associationValue={associationView.associationValue}
              />
            </CompanySectionLayout>
          )
        }
      />
      {/* <InspectionMapCard /> */}
    </React.Fragment>
  )
}
