import { useQuery } from '@apollo/client'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Link,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chaine/keychaine'
import * as React from 'react'

import { COMPANY_ASSOCIATION } from '../../data/queries/company-association'
import { TValueField } from '../../data/queries/types'
import { TCompanyAssociation } from '../../utils/types'

interface IAssociationProps {
  associationField: string
  handleAssociationView: (associationField: string, associationValue: string, title: string) => void
  type: string
  values: TValueField[]
}

interface IAssociationViewProps {
  associationField: string
  associationValue: string
  dotNumber: string
  isOpen: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
}

export function Association({ type, values, associationField, handleAssociationView }: IAssociationProps) {
  return (
    <Flex direction="column">
      <Text fontWeight="600" fontSize={16}>
        {type} Associations
      </Text>{' '}
      <Table>
        <TableContainer>
          <Table variant="simple">
            <TableCaption></TableCaption>
            <Thead>
              <Tr>
                <Th>{type}</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {values?.map((valueField: TValueField, index: number) => (
                <Tr key={type + index}>
                  <Td>{valueField?.value}</Td>
                  <Td>
                    <Link
                      color="blue.400"
                      onClick={() => {
                        handleAssociationView(associationField, valueField?.sanitizedValue || valueField?.value, type)
                      }}
                    >
                      {'View More >'}
                    </Link>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Table>
    </Flex>
  )
}

export function AssociationView({
  isOpen,
  associationValue,
  setOpen,
  associationField,
  title,
  dotNumber
}: IAssociationViewProps) {
  const [isRefetching, setRefetching] = React.useState(false)
  const {
    data: associations,
    loading,
    fetchMore
  } = useQuery<TCompanyAssociation>(COMPANY_ASSOCIATION, {
    fetchPolicy: 'network-only',
    variables: {
      associationField,
      associationValue,
      limit: 10
    }
  })

  function handleShowMore() {
    if (!associations?.association?.isLastPage) {
      setRefetching(true)
      fetchMore({
        updateQuery: (prevResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prevResult
          setRefetching(false)
          return {
            association: {
              ...fetchMoreResult.association,
              items: [...prevResult.association.items, ...fetchMoreResult.association.items]
            }
          }
        },
        variables: {
          next: {
            associationValue: associations?.association?.nextPage?.associationValue,
            dot: associations?.association?.nextPage?.dot
          }
        }
      })
    }
  }

  return (
    <Drawer
      placement="right"
      onClose={() => setOpen(false)}
      isOpen={isOpen}
      size={{ base: 'full', lg: 'xl' }}
      closeOnEsc={true}
    >
      <DrawerOverlay />
      <DrawerContent>
        <Flex>
          <DrawerHeader minWidth="fit-content">{title} Associations</DrawerHeader>
          <DrawerCloseButton />
        </Flex>
        <DrawerBody>
          {loading ? (
            <Flex justifyContent="center">
              <Spinner />
            </Flex>
          ) : (
            <>
              <TableContainer>
                <Table variant="striped">
                  <TableCaption></TableCaption>
                  <Thead>
                    <Tr>
                      <Th>DOT Number</Th>
                      <Th>Company Name</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {associations?.association?.items?.map(
                      (item) =>
                        item?.id != dotNumber && (
                          <Tr key={item?.id}>
                            <Td>
                              <Link color="blue.400" href={`/dot/${item.id}`}>
                                DOT#{item.id}
                              </Link>
                            </Td>
                            <Td>{item?.companyDetails?.legalName?.oldestValue?.value}</Td>
                          </Tr>
                        )
                    )}
                    {(associations?.association === null ||
                      (associations?.association?.items?.length === 1 &&
                        associations?.association?.items[0]?.id === dotNumber)) && (
                      <Tr>
                        <Td colSpan={2}>There are no associations associated with this value</Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
              {associations?.association !== null && !associations?.association?.isLastPage && (
                <Flex justifyContent="center">
                  <Button
                    colorScheme="blue"
                    variant="primary"
                    onClick={handleShowMore}
                    isLoading={loading || isRefetching}
                  >
                    Show More
                  </Button>
                </Flex>
              )}
            </>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
