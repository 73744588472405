import {
  TModifiedValues,
  TMotorCarrierData,
  TMotorCarrierField,
  TMotorCarrierModification,
  TValueField
} from '../data/queries/types'
import { TAllModifications } from './types'

export function mergeModificationsWithMetadata(
  carrierMetadata: TMotorCarrierData | null,
  carrierModifications: TMotorCarrierModification | null
) {
  const keysToConsider: (keyof TMotorCarrierData)[] = [
    'telephone',
    'faxNumber',
    'emailAddress',
    'physicalStreet',
    'physicalCity',
    'physicalState',
    'physicalZip',
    'mailingStreet',
    'mailingCity',
    'mailingState',
    'mailingZip'
  ]

  const modificationKeys = [...keysToConsider] as (keyof TModifiedValues)[]

  const allChanges: TAllModifications = {}
  if (carrierMetadata) {
    keysToConsider.forEach((key) => {
      if (carrierMetadata[key]) {
        allChanges[key] = [(carrierMetadata[key] as TMotorCarrierField)?.oldestValue]
      }
    })
  }

  if (carrierModifications) {
    modificationKeys.forEach((key) => {
      if (carrierModifications?.modifiedValues[key]) {
        // eslint-disable-next-line no-unsafe-optional-chaining
        allChanges[key] = [...(allChanges[key] || []), ...carrierModifications?.modifiedValues[key]]
      }
    })
  }
  return allChanges
}

export function mergeAddress(
  streetArr: TValueField[],
  cityArr: TValueField[],
  stateArr: TValueField[],
  zipArr: TValueField[]
): TValueField[] | void {
  if (!streetArr || !cityArr || !stateArr || !zipArr) {
    return
  }

  const dates = new Set<string>()

  ;[streetArr, cityArr, stateArr, zipArr].forEach((arr) => {
    arr.forEach((item) => dates.add(item.date))
  })

  const sortedDates = Array.from(dates).sort()
  const result: TValueField[] = []

  let lastStreet = '',
    lastCity = '',
    lastState = '',
    lastZip = ''

  sortedDates.forEach((date) => {
    const streetObj = streetArr.find((item) => item.date === date)
    const cityObj = cityArr.find((item) => item.date === date)
    const stateObj = stateArr.find((item) => item.date === date)
    const zipObj = zipArr.find((item) => item.date === date)

    if (streetObj?.value !== undefined && streetObj?.value !== null) lastStreet = streetObj?.value
    if (cityObj?.value !== undefined && cityObj?.value !== null) lastCity = cityObj?.value
    if (stateObj?.value !== undefined && stateObj?.value !== null) lastState = stateObj?.value
    if (zipObj?.value !== undefined && zipObj?.value !== null) lastZip = zipObj.value

    const fullAddress = `${lastStreet}, ${lastCity}, ${lastState}, ${lastZip}`.trim()

    const sanitizedValue = fullAddress.replaceAll(/,/g, ';').replace(/[^a-zA-Z0-9;]/g, '')

    result.push({
      date,
      sanitizedValue,
      value: fullAddress
    })
  })

  return result
}
