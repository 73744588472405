import { gql } from '@apollo/client'

export const COMPANY_ASSOCIATION = gql`
  query companyAssociation(
    $associationField: String!
    $associationValue: String!
    $limit: Int!
    $next: NextPageStartInput
  ) {
    association(
      input: { associationField: $associationField, associationValue: $associationValue, limit: $limit, next: $next }
    ) {
      items {
        id
        modifiedAt
        companyDetails {
          mcNumber
          legalName {
            latestValue {
              value
              date
            }
            oldestValue {
              value
              date
            }
          }
        }
      }
      isLastPage
      nextPage {
        dot
        associationValue
      }
    }
  }
`
