import * as React from 'react';
import { Box, Divider, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalKey, ModalOverlay, Text, useColorModeValue as mode } from '../..';
export { ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Modal as ModalKey, ModalOverlay, useModal, useModalContext } from '@chakra-ui/react';
export const Modal = (props) => {
    const { isOpen, onClose, children, footer, title, size = 'md', isCentered = true, scrollBehavior, showCloseButton = true, subtitle } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalKey, { onClose: onClose, isOpen: isOpen, isCentered: isCentered, size: size, scrollBehavior: scrollBehavior },
            React.createElement(ModalOverlay, null),
            React.createElement(ModalContent, null,
                React.createElement(ModalHeaderLayout, { title: title, subtitle: subtitle, showCloseButton: showCloseButton }),
                React.createElement(ModalBody, null, children),
                footer && React.createElement(ModalFooter, null, footer)))));
};
export const ModalHeaderLayout = ({ title, subtitle, showCloseButton, textAlign = 'left', mt = '0' }) => {
    return (React.createElement(Box, null,
        title && (React.createElement(Box, null,
            title && (React.createElement(ModalHeader, { fontSize: 'lg', textAlign: textAlign, color: mode('text.primary', 'text.secondary') }, title)),
            subtitle && (React.createElement(Text, { pb: 4, px: 6, color: mode('text.secondary', 'text.tertiary') }, subtitle)),
            showCloseButton && React.createElement(ModalCloseButton, { mt: mt }),
            React.createElement(Divider, null))),
        !title && (React.createElement(Box, null,
            subtitle && (React.createElement(Text, { textAlign: textAlign, my: 6, pl: 6, pr: showCloseButton ? 14 : 6, fontSize: 'md', color: mode('text.secondary', 'text.tertiary') }, subtitle)),
            showCloseButton && React.createElement(ModalCloseButton, { color: mode('gray.700', 'gray.500') }),
            React.createElement(Divider, { color: mode('border.light.primary', 'border.dark.primary') })))));
};
