import { gql } from '@apollo/client'

export const MOTOR_CARRIER_DATA = gql`
  query motorCarrierData($value: String!, $type: String!) {
    motorCarrierData(input: { value: $value, type: $type }) {
      addDate {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      allowedToOperate {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      carrierAuthority {
        latestValue {
          value {
            applicantID
            authority
            authorizedForBroker
            authorizedForHouseholdGoods
            authorizedForPassenger
            authorizedForProperty
            brokerAuthorityStatus
            commonAuthorityStatus
            contractAuthorityStatus
            docketNumber
            dotNumber
            prefix
          }
          date
        }
        oldestValue {
          value {
            applicantID
            authority
            authorizedForBroker
            authorizedForHouseholdGoods
            authorizedForPassenger
            authorizedForProperty
            brokerAuthorityStatus
            commonAuthorityStatus
            contractAuthorityStatus
            docketNumber
            dotNumber
            prefix
          }
          date
        }
      }
      carrierOperation {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      dbaName {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      emailAddress {
        latestValue {
          value
          date
          sanitizedValue
        }
        oldestValue {
          value
          date
          sanitizedValue
        }
      }
      faxNumber {
        latestValue {
          value
          date
          sanitizedValue
        }
        oldestValue {
          value
          date
          sanitizedValue
        }
      }
      hmFlag {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      id
      legalName {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      mailingCity {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      mailingCountry {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      mailingState {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      mailingStreet {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      mailingZip {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      mcNumber
      mcsDate {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      mcsMileage {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      mcsMileageYear {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      numberOfDrivers {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      numberOfPowerUnits {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      oicState {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      passengerCarrierFlag {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      physicalCity {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      physicalState {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      physicalStreet {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      physicalZip {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      physicalCountry {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      recentMileage {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      recentMileageYear {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      telephone {
        latestValue {
          value
          date
          sanitizedValue
        }
        oldestValue {
          value
          date
          sanitizedValue
        }
      }
      vmtSourceID {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      privateOnly {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      authorizedForHire {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      exemptForHire {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      privateProperty {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      privatePassengerBusiness {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      privatePassengerNonBusiness {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      migrant {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      usEmail {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      federalGovernment {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      stateGovernment {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      localGovernment {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      indianTribe {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
      opOther {
        latestValue {
          value
          date
        }
        oldestValue {
          value
          date
        }
      }
    }
  }
`

export const MOTOR_CARRIER_MODIFICATIONS = gql`
  query motorCarrierModifications($dotNumber: String!, $type: String!) {
    motorCarrierModifications(input: { value: $dotNumber, type: $type }) {
      id
      mcNumber
      modifiedValues {
        telephone {
          date
          value
        }
        physicalCity {
          date
          value
        }
        physicalState {
          date
          value
        }
        physicalCountry {
          date
          value
        }
        physicalZip {
          date
          value
        }
        mailingCity {
          date
          value
        }
        mailingState {
          date
          value
        }
        mailingCountry {
          date
          value
        }
        mailingZip {
          date
          value
        }
        emailAddress {
          date
          value
        }
        faxNumber {
          date
          value
        }
      }
    }
  }
`
