import { mode } from '../..';
const globalScrollBarStyles = (props) => {
    const thumbColor = mode('rgba(0,0,0,.2)', 'rgba(255,255,255,.2)')(props);
    const thumbColorHover = mode('rgba(0,0,0,.3)', 'rgba(255,255,255,.3)')(props);
    const trackColor = 'rgba(0, 0, 0, 0)';
    return {
        '*': {
            scrollbarWidth: 'thin',
            scrollbarColor: `${thumbColor} ${trackColor}`,
            '&::-webkit-scrollbar': {
                backgroundColor: 'transparent',
                height: '4px',
                width: '4px'
            },
            '&::-webkit-scrollbar-track': {
                background: trackColor,
                borderRadius: '2px'
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: thumbColor,
                borderRadius: '2px',
                maxHeight: '120px',
                minHeight: '40px',
                '&:hover': {
                    backgroundColor: thumbColorHover
                },
                '&:active': {
                    backgroundColor: thumbColorHover
                }
            },
            '@media (hover: hover)': {
                '&:not(:hover)::-webkit-scrollbar-thumb': {
                    backgroundColor: 'transparent'
                },
                '&:not(:hover)::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent'
                }
            },
            '@media (hover: none)': {
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'transparent',
                    transition: 'background-color 0.3s'
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                    transition: 'background-color 0.3s'
                },
                '&::-webkit-scrollbar-thumb:active': {
                    backgroundColor: thumbColor
                },
                '&.scrolling::-webkit-scrollbar-thumb': {
                    backgroundColor: thumbColor
                }
            }
        }
    };
};
export default {
    global: (props) => ({
        body: {
            fontFamily: 'body',
            fontSize: 'md',
            color: mode('text.primary', 'text.primary')(props),
            bg: mode('white', 'gray.900')(props),
            lineHeight: 'base'
        },
        h1: {
            fontFamily: 'heading',
            fontSize: '2rem'
        },
        h2: {
            fontFamily: 'heading',
            fontSize: '1.5rem'
        },
        h3: {
            fontFamily: 'heading',
            fontSize: '1.188rem'
        },
        h4: {
            fontFamily: 'heading',
            fontSize: '1rem'
        },
        h5: {
            fontFamily: 'heading',
            fontSize: '0.875rem'
        },
        h6: {
            fontFamily: 'heading',
            fontSize: '0.6875rem'
        },
        '*::placeholder': {
            opacity: 1,
            color: 'muted'
        },
        '*, *::before, &::after': {
            borderColor: mode('gray.200', 'gray.700')(props)
        },
        'html,body': {
            height: '100%',
            overflowX: 'hidden'
        },
        '#__next, #root': {
            display: 'flex',
            flexDirection: 'column',
            minH: '100%'
        },
        ...globalScrollBarStyles(props)
    })
};
