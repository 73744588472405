export { Menu as BaseMenu, MenuIcon as ChakraMenuIcon, MenuButton, MenuCommand, MenuDivider, MenuGroup, MenuItem, MenuItemOption, MenuList, MenuOptionGroup, useMenu, useMenuButton, useMenuContext, useMenuDescendant, useMenuDescendants, useMenuDescendantsContext, useMenuItem, useMenuList, useMenuOption, useMenuOptionGroup, useMenuPositioner, useMenuState } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Avatar, AvatarBadge, BaseMenu, Box, Center, CheckIcon, ChevronDownIcon, Divider, HStack, Icon, MenuButton, MenuItem, MenuList, Text, useColorMode } from '../..';
const Menu = React.forwardRef(({ hideButtonHover = false, matchWidth = true, menuItems, menuIcon, menuTitle, onChange, onClick, hasBorder, showChevron = false, avatar, buttonAriaLabel, suppressColorMode, children, onClose, ...props }, ref) => {
    const { colorMode } = useColorMode();
    const [hover, setHover] = useState(false);
    const handleHover = () => {
        setHover(!hover);
    };
    return (React.createElement(BaseMenu, { ...ref, matchWidth: matchWidth, onClose: onClose },
        React.createElement(MenuButton, { onMouseEnter: handleHover, onMouseLeave: handleHover, onChange: onChange, onClick: onClick, ...props, ...props.button, "aria-label": buttonAriaLabel, borderRadius: avatar && avatar?.variant === 'basic' ? 'full' : 'md', borderWidth: hasBorder ? '1px' : '', bg: "transparent", _hover: hideButtonHover
                ? undefined
                : {
                    bg: suppressColorMode ? 'whiteAlpha.100' : colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.100',
                    borderRadius: avatar && avatar?.variant === 'basic' ? 'full' : 'md'
                } }, children ? (children) : (React.createElement(HStack, { justifyContent: "space-between", w: "inherit", display: "flex", alignItems: "center" },
            React.createElement(HStack, null,
                avatar ? (React.createElement(Avatar, { variant: avatar.variant || 'square', size: avatar.size || 'xs', src: avatar.src, name: avatar.name }, avatar.showBadge && (React.createElement(AvatarBadge, { borderColor: suppressColorMode ? 'gray.900' : undefined, boxSize: "1.25em", bg: avatar.isOnline ? 'green.500' : 'gray.500' })))) : (React.createElement(Box, null, menuIcon)),
                menuTitle && React.createElement(Text, null, menuTitle.length > 15 ? `${menuTitle.substring(0, 15)}...` : menuTitle)),
            showChevron && React.createElement(Icon, { as: ChevronDownIcon })))),
        React.createElement(MenuList, { p: 1, m: 0, bg: colorMode === 'dark' ? 'gray.800' : 'white', border: colorMode === 'dark' ? '1px solid #535159' : '', boxShadow: colorMode === 'dark' ? '0px 4px 12px rgba(44, 44, 46, 0.15)' : '0px 4px 12px rgba(44, 44, 46, 0.15)', borderRadius: "lg", zIndex: 100, overflow: "auto", maxH: { base: 'calc(100vh - 150px)', md: '45rem' }, css: {
                '&::-webkit-scrollbar': {
                    width: '4px'
                },
                '&::-webkit-scrollbar-track': {
                    width: '6px'
                },
                '&::-webkit-scrollbar-thumb': {
                    background: colorMode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)',
                    borderRadius: '24px'
                },
                '& > *': {
                    pointerEvents: 'auto'
                },
                '&.scrolling > *': {
                    pointerEvents: 'none'
                }
            }, onScroll: (e) => {
                const target = e.currentTarget;
                if (!target.classList.contains('scrolling')) {
                    target.classList.add('scrolling');
                    setTimeout(() => {
                        target.classList.remove('scrolling');
                    }, 200);
                }
            } }, menuItems.map((menuItem, index) => {
            const { avatar: itemAvatar, custom, textProperties, onClick: itemOnClick, isChecked, isDivider, isFullWidthDivider, command, icon, isVisible } = menuItem;
            return isVisible ? null : isDivider ? (React.createElement(Center, { key: index },
                React.createElement(Divider, { my: 1, w: isFullWidthDivider ? '100%' : '95%' }))) : (React.createElement(MenuItem, { key: index, onClick: itemOnClick, py: 1.5, px: 2.5, borderRadius: "md", bg: "transparent", _hover: {
                    bg: colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.100',
                    borderRadius: 'md'
                }, command: isChecked ? '' : command, role: "menuitem", userSelect: "none" },
                React.createElement(HStack, { justifyContent: "space-between", w: "inherit", display: "flex", alignItems: "center" },
                    React.createElement(HStack, null,
                        custom && React.createElement(React.Fragment, null, custom),
                        itemAvatar && (itemAvatar.src || itemAvatar.name) && (React.createElement(Avatar, { variant: itemAvatar.variant || 'square', size: itemAvatar.size || 'xs', src: itemAvatar.src, name: itemAvatar.name }, itemAvatar.showBadge && (React.createElement(AvatarBadge, { boxSize: "1.25em", bg: itemAvatar.isOnline ? 'green.500' : 'gray.500' })))),
                        icon && React.createElement(Icon, { as: icon }),
                        textProperties && textProperties.text && (React.createElement(Text, null, textProperties.text.length > (textProperties.characterLimit || 20)
                            ? `${textProperties.text.substring(0, textProperties.characterLimit || 20)}...`
                            : textProperties.text))),
                    isChecked && React.createElement(Icon, { as: CheckIcon }))));
        }))));
});
Menu.displayName = 'Menu';
export { Menu };
