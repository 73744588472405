import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { useAuth } from '../../auth/data/use-auth'
import { ROUTES } from '../routes-list'

/**
 * RequireAuthWebsite - use this function to wrap private routes.
 * Redirects to the website if not authenticated.
 */
export function RequireAuthWebsite({ children }: { children: JSX.Element }) {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  const getIsAuthenticated = () => isAuthenticated && location.pathname === '/'

  if (!getIsAuthenticated()) {
    if (location.pathname.includes('/terms') || location.pathname.includes('/privacy')) {
      return children
    }

    window.location.href = 'https://trychain.com' // Redirect to website
    return null
  }

  return <Navigate to={ROUTES.ASSIGNED_TO_ME} replace state={{ path: location.pathname, search: location.search }} />
}
