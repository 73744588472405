import { PageSpinner } from '@chaine/keychaine'
import React, { lazy, Suspense } from 'react'

import { MainWebsiteContainer } from '../../_shared/layouts/application/main-container/website-layout'
import { RequireAuthWebsite } from '../hoc'
import { lazyRetry } from '../lazy-retry'

const PrivacyPolicy = lazy(() => lazyRetry(() => import('../../website/privacy'), 'PrivacyPolicy'))
const Terms = lazy(() => lazyRetry(() => import('../../website/terms'), 'Terms'))

export const websiteRoutes = (toggleColorMode: () => void) => {
  return [
    {
      children: [
        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <PrivacyPolicy />
            </Suspense>
          ),
          path: '/privacy'
        },

        {
          element: (
            <Suspense fallback={<PageSpinner />}>
              <Terms />
            </Suspense>
          ),
          path: '/terms'
        }
      ],
      element: (
        <RequireAuthWebsite>
          <MainWebsiteContainer toggleColorMode={toggleColorMode} />
        </RequireAuthWebsite>
      ),
      path: '/'
    }
  ]
}
